import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  PageTransitionContainer,
  Page,
  useLocation,
  findMatchingRoute,
} from '@adac/core-view';
import { observer } from 'mobx-react';

import { keyGenerator } from '@adac/core-model';
import StoresContext from '../../stores';
import { LoginPage } from '../pages/Login';
import ForgotPasswordStart from '../pages/ForgotPassword';

export enum PathNames {
  FORGOT_PASSWORD = '/forgot-password',
  CONFIRM_TOKEN = '/confirm-token',
}

export default observer((): JSX.Element => {
  const stores = useContext(StoresContext);
  const location = useLocation();

  const { key, pathname } = location;

  const routes: JSX.Element[] = [
    <Route
      path={PathNames.FORGOT_PASSWORD}
      exact
      pageTransition='slideLeft'
      component={ForgotPasswordStart}
    />,
    <Route path='/' component={LoginPage} />,
  ];
  // TODO: enable pagetransitions again
  const foundMatchingRoute = findMatchingRoute({ location, routes });
  if (foundMatchingRoute && foundMatchingRoute.props.pageTransition) {
    // NOTE: The reason I don't use pageTranstion just a local reference, using the stores we might be able to have more control, and manage from other place
    stores.ui.setPageTransition(
      foundMatchingRoute && foundMatchingRoute.props.pageTransition
    );
  } else {
    stores.ui.setPageTransition();
  }

  return (
    <PageTransitionContainer
      pageTransition={stores.ui.pageTransition}
      pageTransitionDelay={stores.ui.pageTransitionDelay}
      routes={routes}
      transitionKey={key || pathname}
      ContentStyle={Page}
    >
      <Switch>
        {routes.map((RouteConfig) =>
          React.cloneElement(RouteConfig, {
            key: keyGenerator(RouteConfig.props),
          })
        )}
      </Switch>
    </PageTransitionContainer>
  );
});
