import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Page,
  Title,
  SubPage,
  ErrorMessage,
  LocalizableError,
  useFormikAuth,
  useRouter,
  Text,
  ConfirmTokenForm,
  LoginPage as LoginPageComponent,
  AuthFormValues,
  requestMFATokenKey,
  confirmToken,
  AuthSubmitFunc,
} from '@adac/core-view';
import { __ } from '@adac/core-model';

import StoresContext, { useOverlay } from '../../stores';
import { PathNames } from '../layout/LoginRouterContainer';
import { buttonStyles } from './buttonStyles';

export const LayoutStyles = styled(Page)`
  height: 100%;
  display: flex;
  flex-direction: column;

  fieldset {
    ${buttonStyles}
  }
`;

export const LoginPage = () => {
  const setOverlay = useOverlay();
  const {
    auth: { url, setToken },
  } = React.useContext(StoresContext);
  const {
    history: { push },
  } = useRouter();
  const [username, setUsername] = useState<string | undefined>(undefined);

  const onError = (error: LocalizableError) => {
    if (error.message.match(/(Too many attempts)|(expired)/)) setTokenKey('');
    setOverlay(
      <ErrorMessage>
        {__(
          error.toLocalizedString ? error.toLocalizedString() : error.toString()
        )}
      </ErrorMessage>
    );
  };

  const {
    onSubmit: onStartLogin,
    result: tokenKey,
    setResult: setTokenKey,
  } = useFormikAuth(requestMFATokenKey(url), onError);

  const { onSubmit: onConfirmSubmit, result: jwtToken } = useFormikAuth(
    confirmToken,
    onError
  );

  useEffect(() => {
    setToken(jwtToken || '');
  }, [jwtToken, setToken]);

  const onConfirm: AuthSubmitFunc = async (
    values: AuthFormValues,
    setStatus,
    setSubmitting
  ) => {
    await onConfirmSubmit(values, setStatus, setSubmitting);
    if (jwtToken) push('/');
  };

  const onStartSubmit: AuthSubmitFunc = async (
    values: AuthFormValues,
    setStatus,
    setSubmitting
  ) => {
    setUsername(values.id);
    await onStartLogin(values, setStatus, setSubmitting);
  };

  if (tokenKey) {
    return (
      <LayoutStyles>
        <ConfirmTokenForm
          tokenKey={tokenKey}
          onSubmit={onConfirm}
          onCancel={() => setTokenKey('')}
        >
          <SubPage>
            <Title>{__('Please login to SND Admin')}</Title>
            <br />
            <br />
            <Text>
              {__('verificationMessageStart')}
              {username}
              {__('verificationMessageEnd')}
            </Text>
          </SubPage>
        </ConfirmTokenForm>
      </LayoutStyles>
    );
  }

  return (
    <LayoutStyles>
      <LoginPageComponent
        onSubmit={onStartSubmit}
        onForgot={() => push(PathNames.FORGOT_PASSWORD)}
      >
        <Title>{__('Please login to SND Admin')}</Title>
      </LoginPageComponent>
    </LayoutStyles>
  );
};
