import styled from 'styled-components';
import { View } from '../ui';

export const buttonStyles = `
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const ButtonStyles = styled(View)`
  ${buttonStyles}
`;
