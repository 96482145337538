import { Driver, DriverRecord, getApiRoutes } from '@adac/core-model';
import { request } from '@adac/core-view';
import { useEffect, useState } from 'react';

const useSelectedDriver = (driverId: string): DriverRecord | undefined => {
  const [driver, setDriver] = useState<Driver>();

  useEffect(() => {
    const getDriver = async () => {
      const url = getApiRoutes().driverAdmin.get(driverId);
      try {
        const data = await request<Driver>(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setDriver(data);
      } catch (error) {
        console.error(error);
      }
    };
    if (!Number.isNaN(parseInt(driverId as string, 10))) {
      getDriver();
    }
  }, [driverId]);

  return driver;
};

export default useSelectedDriver;
