import { useContext, useEffect } from 'react';
import { getMyCompany } from '../networking/driverEndpoints';
import StoresContext from '../stores';

export const useMyCompany = () => {
  const { company } = useContext(StoresContext);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const res = await getMyCompany();
        company.setMe(res);
      } catch (error) {
        company.setMe(undefined);
      }
    };
    getCompanies();
  }, [company]);

  return company.getMe;
};
