import { Title, Page, SubPage } from '@adac/core-view';
import { __ } from '@adac/core-model';
import OpenCasesList from '../composite/OpenCasesList';

export default () => (
  <Page>
    <SubPage>
      <Title>{__('All open cases')}</Title>
    </SubPage>
    <SubPage>
      <OpenCasesList withPagination withExport />
    </SubPage>
  </Page>
);
