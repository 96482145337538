import { useContext } from 'react';
import { observer } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import { Layout, browser } from '@adac/core-view';

import StoreContext from '../../stores';
import MainPageContainer from './MainPageContainer';
import OverlayContainer from './OverlayContainer';
import OverlayErrorContainer from '../overlays/OverlayErrorContainer';
import DevTools from '../dev/DevTools';
import { Header } from './Header';
import FooterContainer from './FooterContainer';

export default observer(() => {
  const stores = useContext(StoreContext);
  return (
    <ThemeProvider theme={stores.ui.currentTheme}>
      <Layout nav={Header} browser={browser}>
        <MainPageContainer afterElement={<FooterContainer />} />
        <OverlayContainer />
        <OverlayErrorContainer />
        <DevTools />
      </Layout>
    </ThemeProvider>
  );
});
