import { useState, useContext } from 'react';
import { observer } from 'mobx-react';

import { BasicDevTool } from '@adac/core-view';

import StoreContext from '../../stores';

export default observer(() => {
  const stores = useContext(StoreContext);
  const [open, setOpen] = useState(false);

  return (
    <BasicDevTool open={open} setOpen={setOpen} stores={stores}>
      <section />
    </BasicDevTool>
  );
});
