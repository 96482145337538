import { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  AddressOverlayContainer,
  CreateOrUpdateDriver,
  DriverDeleteButton,
  ErrorMessage,
  ManageDriver,
  Page,
  SubPage,
  SubTitle,
  themeConfig,
  Title,
  useLocation,
  useValidationSchema,
} from '@adac/core-view';

import { __, isDriverBoss, UserRole } from '@adac/core-model';

import styled from 'styled-components';
import { useMyCompany } from '../../hooks/useMyCompany';
import useSelectedDriver from '../../hooks/useSelectedDriver';
import {
  createDriver,
  deleteDriver,
  updateDriver,
} from '../../networking/driverEndpoints';
import StoreContext from '../../stores';

export type LocalizeableError = {
  toLocalizedString: () => string;
};

const DeleteButton = styled(DriverDeleteButton)`
  border: 1px solid ${themeConfig.colors.warning};
  margin: 16px 0px;
  padding: 13px 0px;
  border-radius: 4px;
`;

const ManageDrivers = (
  props: RouteComponentProps<{ driverId: string; action: string }>
) => {
  const { auth: authStore, ui: uiStore } = useContext(StoreContext);
  const { goBack } = useLocation();
  const myCompany = useMyCompany();

  const driverId = props.match.params.driverId;
  const isNewDriver = props.match.params.action === 'new' || !driverId;
  const driver = useSelectedDriver(driverId);
  const validationSchema = useValidationSchema(driver);

  const onSubmit = async (values: CreateOrUpdateDriver) => {
    try {
      if (driverId) {
        await updateDriver(driverId, values);
      } else {
        await createDriver(values);
      }
      goBack();
    } catch (error) {
      uiStore.setOverlay(
        <ErrorMessage>
          {__((error as LocalizeableError)?.toLocalizedString() ?? `${error}`)}
        </ErrorMessage>
      );
    }
  };

  const onDeleteDriver = async () => {
    await deleteDriver(driverId);
    goBack();
  };

  if (driverId && !Number.isNaN(driverId) && !driver)
    return <SubTitle>{__('Driver not found')}</SubTitle>;

  // only boss can edit boss data
  if (!authStore.isUserRoleBoss && isDriverBoss(driver)) {
    return (
      <ErrorMessage>
        {__('You do not have permission to edit boss')}
      </ErrorMessage>
    );
  }
  return (
    <Page>
      <AddressOverlayContainer>
        <SubPage>
          <Title>
            {isNewDriver
              ? 'Neuen Mitarbeiter hinzufügen'
              : `${driver?.firstName} ${driver?.familyName}`}
          </Title>
          {(driver !== undefined || isNewDriver) && myCompany && (
            <ManageDriver
              companyId={myCompany.id}
              driver={driver}
              onSubmit={onSubmit}
              onBack={goBack}
              validationSchema={validationSchema}
              disablePrivatePhoneForBoss
            >
              {!driver?.user?.roles?.find(
                (role) => role.name === UserRole.COMPANY
              ) &&
                !isNewDriver && <DeleteButton onClick={onDeleteDriver} />}
            </ManageDriver>
          )}
        </SubPage>
      </AddressOverlayContainer>
    </Page>
  );
};

export default ManageDrivers;
