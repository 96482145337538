import { useState } from 'react';

import { Children } from '@adac/core-view';
import { GetterType } from '../../networking/getCases';
import { CaseTableCellItem, getCasesIntoCellItems } from './caseListComponents';
import { ListView, Accessories, Params } from '../ListView';
import { CaseTable } from '../CaseTable';

export interface ListLimitProps extends Accessories {
  limit?: number;
  children?: Children;
}
interface CaseListProps extends ListLimitProps {
  showInvoice: boolean;
  getter: (token: string, params?: Params) => Promise<GetterType>;
}

export const CasesList = (props: CaseListProps) => {
  const { limit, getter, showInvoice } = props;
  const [filterParams, setFilterParams] = useState<Record<string, string>>({});

  function listGetter(token: string, params?: Params) {
    const finalParams = limit
      ? { perPage: limit, page: 0, ...filterParams }
      : { ...params, ...filterParams };

    return getCasesIntoCellItems(token, getter, finalParams);
  }

  return (
    <ListView
      {...props}
      // eslint-disable-next-line react/jsx-no-bind
      getList={listGetter}
      renderList={(items: CaseTableCellItem[]) => (
        <CaseTable
          items={items}
          showInvoice={showInvoice}
          setFilterParams={setFilterParams}
        />
      )}
    />
  );
};
