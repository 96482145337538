import { useContext, createContext } from 'react';

import {
  themeConfig,
  AuthStore,
  CaseStore,
  UIStore,
  CompanyStore,
  searchCaseByTokenForPartner,
  Children,
} from '@adac/core-view';

import { CipheredToken, CompanyBoss, getApiRoutes } from '@adac/core-model';

import { getMe } from '../networking/getMe';

export interface Stores {
  ui: UIStore;
  company: CompanyStore;
  auth: AuthStore<CompanyBoss>;
  case: CaseStore;
}

const uiStore = new UIStore(themeConfig);
const authStore = new AuthStore(getApiRoutes().auth.loginToCompanyAdmin, getMe);
const companyStore = new CompanyStore();
const caseStore = new CaseStore<CipheredToken>(searchCaseByTokenForPartner);
const stores: Stores = {
  ui: uiStore,
  company: companyStore,
  auth: authStore,
  case: caseStore,
};

const StoresContext = createContext(stores);
export default StoresContext;

export const useOverlay = () => {
  const { ui: uiStore } = useContext(StoresContext);
  return (c?: Children) => uiStore.setOverlay(c);
};
export const useTimedOverlay = () => {
  const { ui: uiStore } = useContext(StoresContext);
  return (c?: Children) => uiStore.setTimedOverlay(c);
};
