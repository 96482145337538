import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { DialogStyle, ErrorOverlay, LocalizableError } from '@adac/core-view';
import { keyGenerator } from '@adac/core-model';

import StoreContext from '../../stores';

export default observer(() => {
  const { case: caseStore, ui: uiStore } = useContext(StoreContext);

  const onClose = () => caseStore.clearErrors();
  const onClose2 = () => uiStore.clearErrors();

  const errorList: JSX.Element[] = [];

  const getErrorStrings = (errors: LocalizableError[]) =>
    errors.map((error) =>
      error.toLocalizedString ? error.toLocalizedString() : error.toString()
    );

  const errors = errorList
    .concat(
      (caseStore.hasError && (
        <ErrorOverlay
          key='caseError'
          errors={getErrorStrings(caseStore.errors)}
          onClose={onClose}
        />
      )) ||
        []
    )
    .concat(
      (uiStore.hasError && (
        <ErrorOverlay
          key='error'
          errors={getErrorStrings(uiStore.errors)}
          onClose={onClose2}
        />
      )) ||
        []
    );

  const open = errors.length > 0;

  return (
    <DialogStyle open={open} column end='true'>
      {errors.map((Error) =>
        React.cloneElement(Error, {
          key: keyGenerator(Error.props, ['onStatus']),
        })
      )}
    </DialogStyle>
  );
});
